import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class AssociateService {
  constructor(private http: HttpClient) {}

  addAssociate(associateData: any): Observable<any> {
    return this.http.post<any>(environment.host + '/associate.php', {
      gofor: 'addAssociate',
      associate: {
        ...associateData,
      },
    });
  }

  viewAssociate(associateId: string): Observable<any> {
    return this.http.get<any>(environment.host + '/associate.php', {
      params: new HttpParams({
        fromObject: {
          gofor: 'viewAssociate',
          id: associateId,
        },
      }),
    });
  }

  getAssociate(associateId: string): Observable<any> {
    return this.http.get<any>(environment.host + '/associate.php', {
      params: new HttpParams({
        fromObject: {
          gofor: 'getAssociate',
          id: associateId,
        },
      }),
    });
  }

  updateAssociate(associateId: string, associateData: any): Observable<any> {
    return this.http.put<any>(environment.host + '/associate.php', {
      gofor: 'updateAssociate',
      id: associateId,
      associate: {
        ...associateData,
      },
    });
  }

  deleteAssociate(associateId: string): Observable<any> {
    return this.http.put<any>(environment.host + '/associate.php', {
      gofor: 'deleteAssociate',
      id: associateId,
    });
  }

  getAllAssociates(payload: any): Observable<any[]> {
    return this.http.post<any[]>(environment.host + '/associate.php', {
      gofor: 'getAllAssociates',
      ...payload,
    });
  }

  getAssociateList(): Observable<any> {
    return this.http.post<any>(environment.host + '/associate.php', {
      gofor: 'getAssociateList',
    });
  }
}
