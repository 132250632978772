import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { AssociateService } from '../service/api/associate/associate.service';
import { ToastrService } from 'ngx-toastr';
import { catchError, concatMap, EMPTY } from 'rxjs';
import { Navigate } from '@ngxs/router-plugin';

export interface associatemodel {
  item: any;
  id: string | null;
  list: any[];
}

export class AddAssociate {
  static readonly type = '[AddAssociate] AssociateState';
  constructor(public payload: any) {}
}

export class UpdateAssociate {
  static readonly type = '[UpdateAssociate] AssociateState';
  constructor(public payload: any) {}
}

export class GetAssociate {
  static readonly type = '[GetAssociate] AssociateState';
  constructor(public id: string) {}
}

export class FetchAssociates {
  static readonly type = '[FetchAssociates] AssociateState';
}

export class ViewAssociate {
  static readonly type = '[ViewAssociate] AssociateState';
  constructor(public id: string) {}
}

export class DeleteAssociate {
  static readonly type = '[DeleteAssociate] AssociateState';
  constructor(public id: string) {}
}

@State<associatemodel>({
  name: 'associate',
  defaults: {
    list: [],
    id: null,
    item: null,
  },
})
@Injectable()
export class AssociateState {
  constructor(
    private associateService: AssociateService,
    private toastService: ToastrService
  ) {}

  @Selector()
  static getList(state: associatemodel): any[] {
    return state.list;
  }

  @Selector()
  static getItem(state: associatemodel): any {
    return state.item;
  }

  @Action(AddAssociate)
  addAssociate(states: StateContext<associatemodel>, action: AddAssociate) {
    return this.associateService.addAssociate(action.payload).pipe(
      concatMap((res) => {
        console.log(res);
        this.toastService.success(res.message, '', {
          timeOut: 3000,
          positionClass: 'toast-top-right',
          closeButton: true,
        });
        return states.dispatch([new Navigate(['/', 'home', 'associate'])]);
      }),
      catchError((error) => {
        console.log(error);
        return EMPTY;
      })
    );
  }

  @Action(UpdateAssociate)
  updateAssociate(
    states: StateContext<associatemodel>,
    action: UpdateAssociate
  ) {
    let id = states.getState().id as string;
    return this.associateService.updateAssociate(id, action.payload).pipe(
      concatMap((res) => {
        console.log(res);
        this.toastService.success(res.message, '', {
          timeOut: 3000,
          positionClass: 'toast-top-right',
          closeButton: true,
        });
        return states.dispatch([new Navigate(['/', 'home', 'associate'])]);
      }),
      catchError((error) => {
        console.log(error);
        return EMPTY;
      })
    );
  }

  @Action(GetAssociate)
  editAssociate(states: StateContext<associatemodel>, action: GetAssociate) {
    return this.associateService.getAssociate(action.id).pipe(
      concatMap((res) => {
        console.log(res);
        states.patchState({
          item: res.message,
          id: action.id,
        });
        return EMPTY;
      })
    );
  }

  @Action(ViewAssociate)
  fetchAssociateById(
    states: StateContext<associatemodel>,
    action: ViewAssociate
  ) {
    return this.associateService.viewAssociate(action.id).pipe(
      concatMap((res) => {
        console.log(res);
        states.patchState({
          item: res.message,
        });
        return EMPTY;
      })
    );
  }

  @Action(DeleteAssociate)
  deletAssociate(states: StateContext<associatemodel>, action: ViewAssociate) {
    return this.associateService.deleteAssociate(action.id).pipe(
      concatMap((res) => {
        console.log(res);
        this.toastService.success(res.message, '', {
          timeOut: 3000,
          positionClass: 'toast-top-right',
          closeButton: true,
        });
        return states.dispatch([new Navigate(['/', 'home', 'associate'])]);
      }),
      catchError((error) => {
        console.log(error);
        return EMPTY;
      })
    );
  }

  @Action(FetchAssociates)
  fetchAssociates(
    states: StateContext<associatemodel>,
    action: FetchAssociates
  ) {
    return this.associateService.getAssociateList().pipe(
      concatMap((res) => {
        console.log(res);
        states.patchState({
          list: res.message.associateList,
        });
        return EMPTY;
      })
    );
  }
}
